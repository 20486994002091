import Vue from 'vue'
import VueRouter from 'vue-router'

const Home = () => import('views/home/Home')
const Jiameng = () => import('views/jiameng/Jiameng')
const Fangan = () => import('views/fangan/Fangan')
const Juhezy = () => import('views/juhezy/Juhezy')
const Shop = () => import('views/shop/Shop')
const About = () => import('views/about/About')
const Xymxy = () => import('views/products/Xueyamaixiangyi')
const Jingluoyi = () => import('views/products/Jingluoyi')
const AppDownload = () => import('views/products/AppDownload')
const AppInfo = () => import('views/products/AppInfo')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/investment'
  },
  {
    path: '/home',
    component: Home
  },
  {
    path: '/investment',
    component: Jiameng
  },
  {
    path: '/scheme',
    component: Fangan
  },
  {
    path: '/juhezy',
    component: Juhezy
  },
  {
    path: '/shop',
    component: Shop
  },
  {
    path: '/xymxy',
    component: Xymxy
  },
  {
    path: '/jly',
    component: Jingluoyi
  },
  {
    path: '/appDownload',
    component: AppDownload
  },
  {
    path: '/appInfo',
    component: AppInfo
  },
  {
    path: '/about',
    component: About
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
