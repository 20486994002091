<template>
  <div id="app">
    <top/>
    <router-view/>
    <foot/>
  </div>
</template>

<script>
  import Top from "components/content/top/Top";
  import Foot from 'components/content/foot/Foot';

  export default {
    name: 'App',
    components: {
      Top,
      Foot
    }
  }
</script>

<style>
  @import "./assets/css/base.css";
</style>
