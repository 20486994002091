<template>
  <div class="tab-bar-item" :style="activeStyle" @click="itemClick">
    <div v-if="!isActive">
      <slot name="item-icon"></slot>
    </div>
    <div v-else>
      <slot name="item-icon-active"></slot>
    </div>
    <div>
      <slot name="item-text"></slot>
    </div>
    <div class="menu">
      <slot name="menu"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabBarItem",
  props: {
    path: String,
    activeColor: {
      type: String,
      default: '#ff7008'
    }
  },
  data() {
    return {
      // isActive: false
    }
  },
  computed: {
    isActive() {
      // 判断目前处于活跃的路由是否就是此路由
      return this.$route.path.indexOf(this.path) !== -1;
    },
    activeStyle() {
      // 颜色样式动态控制
      return this.isActive ? {'background-color': this.activeColor} : {};
    }
  },
  methods: {
    itemClick() {
      this.$router.replace(this.path)
    }
  }
}
</script>

<style scoped>
  .tab-bar-item {
    flex: 1;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 71px;
    line-height: 64px;
    color: rgb(255, 255, 255);
    font-size: 20px; 
    position: relative;
  }

  .tab-bar-item img {
    width: 27px;
    height: 26px;
    margin-top: 3px;
    /*margin-bottom: 3px;*/
    vertical-align: middle;
  }

  .menu {
    position: absolute;
    top: 71px;
    font-size: 18px;
    background-color: #951e23;
  }
</style>
