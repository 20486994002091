<template>
  <div class="main-tabbar">
    <tab-bar>
      <tab-bar-item path="/home">
        <div slot="item-text">网站首页</div>
      </tab-bar-item>
      <tab-bar-item path="/investment">
        <div slot="item-text">招商加盟</div>
      </tab-bar-item>
      <tab-bar-item path="/scheme">
        <div slot="item-text">医馆方案</div>
      </tab-bar-item>
      <tab-bar-item path="/juhezy">
        <div slot="item-text">聚合中医</div>
      </tab-bar-item>
      <tab-bar-item path="/shop">
        <div slot="item-text">健康商城</div>
      </tab-bar-item>
      <tab-bar-item path="/products">
        <div slot="item-text">产品介绍</div>
        <div slot="menu">
          <div>智能血压脉象仪</div>
          <div>便携式经络检测仪</div>
        </div>
      </tab-bar-item>
      <tab-bar-item path="/about">
        <div slot="item-text">关于我们</div>
      </tab-bar-item>
    </tab-bar>
  </div>
</template>

<script>
  import TabBar from "components/common/tabbar/TabBar"
  import TabBarItem from "components/common/tabbar/TabBarItem"

  export default {
    name: "MainTabBar",
    components: {
      TabBar,
      TabBarItem
    }
  }
</script>

<style scoped>
  .main-tabbar {
    background-color: #951e23;
    padding: 0 106px;
  }
</style>
